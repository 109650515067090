import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'

const PlanetMyLove = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">never look a gift biosphere in the mouth</span>
        </div>
      </div>
    </section>
    <section className="section song">
      <div className="container song is-fluid">
        <div className="columns">
          <div className="column is-two-thirds art planet_my_love">
            <a className="img-cite" href="https://pngtree.com/freebackground/planet-moon-space-star-background_185272.html">pngtree.com</a>
          </div>
          <div className="column is-one-third lyrics">
            <p>
              Planet my love <br/>
              I know that we’re dying <br/>
              Your rivers run empty <br/>
              My air fades away
            </p>

            <p>
              Our children retreat <br/>
              into your deepest havens <br/>
              Your surface lies barren <br/>
              ’neath the sun’s deadly rays
            </p>

            <p>
              The pain in my heart <br/>
              The sorrow I feel <br/>
              The dread separation <br/>
              This grief is too real
            </p>

            <p>
              But I can’t regret <br/>
              the moment I found you <br/>
              The joy of our union <br/>
              in eternity bound
            </p>

            <p>
              Fairness, beauty, compassion <br/>
              the province of good mortal beings <br/>
              The universe cares not for its children <br/>
              who wait for its pitiless sting
            </p>

            <p>
              But we made a gift unto heaven <br/>
              our love, precious and free <br/>
              As we fade to oblivion <br/>
              love is our victory!
            </p>

            <p>
              Planet my love <br/>
              I join you in dying <br/>
              There’s no greater glory <br/>
              than to share our last day
            </p>

            <p>
              So let us embrace <br/>
              as we walk through this door <br/>
              Though our life here is over <br/>
              our love will yet live&hellip; forevermore!
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default PlanetMyLove
